import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import joiningImage from '../../assets/img/joining.gif';
import config from '../../config';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useVenueMode } from '../../hooks/useVenueMode';
import logger from '../../logger/logger';
import { VenueMode } from '../../types';
import {
  type ChatParticipant,
  ClientTypeUtils,
  type Participant,
} from '../../types/user';
import { uuidv4 } from '../../utils/common';
import { getToken } from '../../utils/getToken';
import { ChatNotifsContainer } from '../ChatNotifs/ChatNotifs';
import { useIsLiveGamePlay } from '../Game/hooks';
import { ChatGlobeIcon } from '../icons/Chat/ChatGlobeIcon';
import { ChatTeamIcon } from '../icons/Chat/ChatTeamIcon';
import { HostIcon } from '../icons/HostIcon';
import { useMyInstance } from '../Player';
import {
  type RightPanelTabProps,
  RightPanelTabState,
} from '../RightPanelContext';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { getRandomColor } from './color';
import {
  ChannelType,
  ChatMode,
  ErrorMessageType,
  FakeChannelId,
  getChatUserId,
  getChatUserName,
  isEditingActive,
  MaxMessageCount,
  MaxMessageTextSize,
  MentionTrigger,
  type Recipient,
  StreamChatEventType,
  StreamChatMessageType,
  TypingIndicatorConfig,
} from './common';
import {
  ChatContextProvider,
  useSelectChatAudiences,
  useSelectChatHost,
} from './Context';
import { MessageInput } from './MessageInput';
import { MessageList } from './MessageList';
import { MessagePreview } from './MessagePreview';
import ChatService, {
  type RemoteTypingUser,
  type SCEvent,
  type SCMessageExtensions,
  type SCMessageType,
  type SCUserType,
} from './service';
import { useChatSharedContext } from './SharedContext';
import { TypingIndicator } from './TypingIndicator';

const log = logger.scoped('chat');

type MiniChatConfig = {
  enabled: boolean;
  version: 'v1' | 'v2';
  inputEnabled: boolean;
};

type ChatProps = {
  miniChatConfig: MiniChatConfig;
};

const myColor = getRandomColor();

const Chat = (
  props: RightPanelTabProps &
    ChatProps & {
      me: Participant;
      privateChannelEnabled: boolean;
    }
) => {
  const {
    me,
    tab,
    handlePanelUIAction,
    privateChannelEnabled,
    miniChatConfig,
  } = props;
  const mode =
    tab === RightPanelTabState.FullChat
      ? ChatMode.Full
      : tab === RightPanelTabState.MiniChat && miniChatConfig.enabled
      ? ChatMode.Preview
      : ChatMode.None;
  const venueId = useVenueId();
  const socketUrl = `${config.api.baseWsUrl}/venues/${venueId}/chat`;
  const token = getToken();

  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const host = useSelectChatHost();
  const audiences = useSelectChatAudiences();
  const venueMode = useVenueMode();

  const [initFailed, setInitFailed] = useState<boolean>(false);
  const [chatUser, setChatUser] = useState<SCUserType | null>(null);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [activeRecipient, setActiveRecipient] = useState<Recipient | null>(
    null
  );
  const [messages, setMessages] = useState<SCMessageType[]>([]);
  const [mentionedRecipients, setMentionedRecipients] = useState<Recipient[]>(
    []
  );
  const [error, setError] = useState<string | null>(null);
  const [fadeoutMessage, setFadeoutMessage] = useToggle(
    mode === ChatMode.Preview
  );
  const [
    hasNewMessagesAfterStopScrolling,
    setHasNewMessagesAfterStopScrolling,
  ] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useToggle(true);
  const [remoteTypingUsers, setRemoteTypingUsers] = useState<
    RemoteTypingUser[]
  >([]);
  const [loaded, setLoaded] = useToggle(false);
  const activeRecipientRef = useRef<Recipient | null>(null);
  activeRecipientRef.current = activeRecipient;
  const recipientsRef = useRef<Recipient[]>([]);
  recipientsRef.current = recipients;
  const messagesRef = useRef<SCMessageType[]>([]);
  messagesRef.current = messages;
  const chatModeRef = useRef<ChatMode>(mode);
  chatModeRef.current = mode;
  const remoteTypingUsersRef = useRef<RemoteTypingUser[]>([]);
  remoteTypingUsersRef.current = remoteTypingUsers;
  const mentionedRecipientsRef = useRef<Recipient[]>([]);
  mentionedRecipientsRef.current = mentionedRecipients;
  mentionedRecipientsRef.current = mentionedRecipients;
  const tabRef = useRef<RightPanelTabState>(tab);
  tabRef.current = tab;
  const messageListElRef = useRef<HTMLDivElement | null>(null);
  const messageInputElRef = useRef<HTMLTextAreaElement | null>(null);
  const chatServiceRef = useRef<ChatService>(new ChatService());
  const errorTimerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const stopWorkersRef = useRef<boolean>(false);
  const localUid = useInstance(() => getChatUserId(me));
  const localUidRef = useRef(localUid);
  const localUsername = useInstance(() => getChatUserName(me));
  const { setChatInited, chatToClientId, setChatToClientId } =
    useChatSharedContext();
  const excludeHost = !useIsLiveGamePlay();
  const miniChatVersionRef = useRef(miniChatConfig.version);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    queryParams: {
      token: token ?? '',
      username: encodeURIComponent(localUsername),
      color: myColor.replace('#', ''),
    },
    onOpen: () => sendTeamChangeMessage(me.teamId ?? ''),
    onClose: () => log.info('WebSocket connection closed.'),
    onError: (event) => log.error('WebSocket error: ', event),
    shouldReconnect: () => true,
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
    },
  });

  const findRecipientByClientId = useLiveCallback(
    (clientId: string | undefined): Recipient | null => {
      if (!clientId) return null;
      return recipientsRef.current.find((o) => o.clientId === clientId) || null;
    }
  );

  const findRecipientByRid = useLiveCallback(
    (rid: string): Recipient | null => {
      return recipientsRef.current.find((o) => o.id === rid) || null;
    }
  );

  const sendTeamChangeMessage = useCallback(
    (teamId: string) => {
      sendMessage(
        JSON.stringify({
          type: 'team_change',
          extensions: {
            venueId,
            channelType: ChannelType.Group,
            senderClientId: myClientId,
            senderRid: localUidRef.current,
            teamId,
          },
        })
      );
    },
    [venueId, myClientId, sendMessage]
  );

  useEffect(() => {
    if (me.teamId) {
      sendTeamChangeMessage(me.teamId);
    }
  }, [me.teamId, sendTeamChangeMessage]);

  chatServiceRef.current.scrollToBottom = useLiveCallback(() => {
    const el = messageListElRef.current;
    if (el && !chatServiceRef.current.isUserScrolling) {
      el.scrollTop = el.scrollHeight - el.clientHeight;
      setHasNewMessagesAfterStopScrolling(false);
    }
  });

  const handleSetError = useLiveCallback(
    (
      errorType: ErrorMessageType,
      errorExtra?: Record<string, unknown>,
      timeout = 3000
    ) => {
      let _error;
      switch (errorType) {
        case ErrorMessageType.EmptyMessageTextSizeError:
          _error = 'Message is empty';
          break;
        case ErrorMessageType.MaxMessageTextSizeError:
          _error = `Max ${MaxMessageTextSize} characters`;
          break;
        case ErrorMessageType.ChannelNotAvailableError:
          if (errorExtra?.channelType === ChannelType.Group) {
            _error = 'You are no longer in that Team';
          } else if (errorExtra?.channelType === ChannelType.Private) {
            _error = 'User is not online';
          } else {
            _error = 'The recipient is no longer available';
          }
          break;
        case ErrorMessageType.DeleteMessageError:
          _error = 'Message delete failed, please try again';
          break;
        case ErrorMessageType.DeleteMessageLaterError:
          _error = 'Please delete it later';
          break;
        case ErrorMessageType.None:
          _error = null;
          break;
        default:
          _error = null;
      }
      setError(_error);
      if (errorTimerIdRef.current) {
        clearTimeout(errorTimerIdRef.current);
        errorTimerIdRef.current = null;
      }
      if (!_error) {
        timeout = 0;
      }
      if (timeout) {
        errorTimerIdRef.current = setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  );

  const appendMessages = useCallback(
    (msgs: SCMessageType[]) => {
      let newMessages: SCMessageType[] = [];
      let appendedNewMessagesCount = 0;
      const uniqueIdMap = new Map();
      const _appenderFactory = (isNewMessage: boolean) => {
        return (m: SCMessageType) => {
          if (!uniqueIdMap.has(m.id)) {
            const extensions = m.extensions || ({} as SCMessageExtensions);
            if (extensions.channelType === ChannelType.Private) {
              const isMyMessage =
                extensions.senderClientId === myClientId ||
                extensions.receiverClientId === myClientId;
              if (!isMyMessage) return;
            }
            if (
              extensions.clientRefId &&
              uniqueIdMap.has(extensions.clientRefId)
            ) {
              const index = uniqueIdMap.get(extensions.clientRefId);
              newMessages[index] = m;
              uniqueIdMap.delete(extensions.clientRefId);
              uniqueIdMap.set(m.id, index);
              log.debug('local message replaced', {
                clientRefId: extensions.clientRefId,
                index: index,
                message: m,
              });
            } else {
              newMessages.push(m);
              if (isNewMessage) {
                appendedNewMessagesCount += 1;
              }
              uniqueIdMap.set(m.id, newMessages.length - 1);
            }
          }
        };
      };
      messagesRef.current.forEach(_appenderFactory(false));
      msgs.forEach(_appenderFactory(true));
      newMessages = newMessages.slice(
        Math.max(newMessages.length - MaxMessageCount, 0)
      );
      setMessages(newMessages);
      if (
        (chatModeRef.current === ChatMode.None ||
          (chatModeRef.current === ChatMode.Preview &&
            miniChatVersionRef.current === 'v2')) &&
        appendedNewMessagesCount > 0
      ) {
        chatServiceRef.current.incrUnreadCount(appendedNewMessagesCount);
      }
      if (
        chatServiceRef.current.isUserScrolling &&
        appendedNewMessagesCount > 0
      ) {
        setHasNewMessagesAfterStopScrolling(true);
      }
    },
    [myClientId]
  );

  const replaceMessage = (message: SCMessageType) => {
    const newMessages: SCMessageType[] = [];
    let replaced = false;
    messagesRef.current.forEach((m) => {
      if (m.id === message.id) {
        newMessages.push(message);
        replaced = true;
      } else {
        newMessages.push(m);
      }
    });
    if (replaced) {
      setMessages(newMessages);
    }
  };

  const switchActiveRecipient = useLiveCallback(
    (message: SCMessageType, force = false) => {
      const canSwitch =
        activeRecipientRef.current?.type === ChannelType.Public &&
        message.extensions?.channelType === ChannelType.Private &&
        message.extensions.receiverRid === localUidRef.current &&
        !message.local &&
        !isEditingActive();
      if (canSwitch || force) {
        const recipient = message.extensions?.senderRid
          ? findRecipientByRid(message.extensions?.senderRid)
          : null;
        if (recipient) {
          setActiveRecipient(recipient);
        } else {
          handleSetError(ErrorMessageType.ChannelNotAvailableError, {
            channelType: message.extensions?.channelType,
          });
        }
      }
    }
  );

  const handleMessageNew = useLiveCallback((event: SCEvent) => {
    if (event.message) {
      appendMessages([event.message]);
      switchActiveRecipient(event.message);
    }
  });

  const handleMessageDeleted = useLiveCallback((event: SCEvent) => {
    if (event.message) {
      replaceMessage(event.message);
    }
  });

  const handleTypingStart = useLiveCallback((event: SCEvent) => {
    if (
      event.user?.id === chatUser?.id ||
      chatModeRef.current !== ChatMode.Full
    ) {
      return;
    }
    const now = new Date().getTime();
    const newRemoteTypingUsers: RemoteTypingUser[] = [];
    let found = false;
    for (let i = 0; i < remoteTypingUsersRef.current.length; i++) {
      const u = remoteTypingUsersRef.current[i];
      if (u.user.id === event.user?.id) {
        log.debug('RemoteTypingUser - updated', {
          user: event.user,
        });
        newRemoteTypingUsers.push({ lastUpdatedTime: now, user: event.user });
        found = true;
        continue;
      }
      newRemoteTypingUsers.push(u);
    }
    if (!found && event.user) {
      newRemoteTypingUsers.push({ lastUpdatedTime: now, user: event.user });
      log.debug('RemoteTypingUser - added', { user: event.user });
    }
    setRemoteTypingUsers(newRemoteTypingUsers);
  });

  const handleTypingStop = useLiveCallback((event: SCEvent) => {
    if (
      event.user?.id === chatUser?.id ||
      chatModeRef.current !== ChatMode.Full
    ) {
      return;
    }
    const newRemoteTypingUsers = [];
    let updated = false;
    for (let i = 0; i < remoteTypingUsersRef.current.length; i++) {
      const u = remoteTypingUsersRef.current[i];
      if (u.user.id === event.user?.id) {
        log.debug('RemoteTypingUser - removed', {
          user: event.user,
        });
        updated = true;
        continue;
      }
      newRemoteTypingUsers.push(u);
    }
    if (updated) {
      setRemoteTypingUsers(newRemoteTypingUsers);
    }
  });

  const handlersRef = useRef({
    appendMessages,
    handleTypingStart,
    handleTypingStop,
    switchActiveRecipient,
  });

  useEffect(() => {
    handlersRef.current = {
      appendMessages,
      handleTypingStart,
      handleTypingStop,
      switchActiveRecipient,
    };
  }, [
    appendMessages,
    handleTypingStart,
    handleTypingStop,
    switchActiveRecipient,
  ]);

  const handleWebSocketMessage = useCallback((messageData: string) => {
    const handlers = handlersRef.current;
    const parsedMessage = JSON.parse(messageData);

    switch (parsedMessage.type) {
      case 'message':
        handlers.appendMessages([parsedMessage]);
        handlers.switchActiveRecipient(parsedMessage);
        break;
      case 'typing_start':
        handlers.handleTypingStart(parsedMessage);
        break;
      case 'typing_stop':
        handlers.handleTypingStop(parsedMessage);
        break;
      default:
        console.warn('Unknown message type:', parsedMessage.type);
    }
  }, []);

  useEffect(() => {
    if (lastMessage?.data) {
      handleWebSocketMessage(lastMessage.data);
    }
  }, [lastMessage, handleWebSocketMessage]);

  const eventHandler = useLiveCallback((event: SCEvent) => {
    log.debug(`received ${event.type}`, { event: event });
    switch (event.type) {
      case StreamChatEventType.MessageNew:
        handleMessageNew(event);
        break;
      case StreamChatEventType.MessageDeleted:
        handleMessageDeleted(event);
        break;
      case StreamChatEventType.TypingStart:
        handleTypingStart(event);
        break;
      case StreamChatEventType.TypingStop:
        handleTypingStop(event);
        break;
      default:
        log.warn(`Unknown ${event.type}`);
    }
  });

  const startRemoteTypingUserCheckWorker = () => {
    log.debug('RemoteTypingUserCheckWorker - start');
    setTimeout(function run() {
      const now = new Date().getTime();
      const newRemoteTypingUsers = [];
      let updated = false;
      for (let i = 0; i < remoteTypingUsersRef.current.length; i++) {
        const u = remoteTypingUsersRef.current[i];
        // If the remote peer keeps typing, it will send the `typing.start` event every ${typingStartSendInterval} secs.
        // Which means, if the lastUpdatedTime is older than the threshold, we can treat the typing stopped.
        // In most cases, the remote peer will send `typing.stop` to indicate the typing has stopped, but considering
        // the unexpected edges cases like network issue, we use this `heartbeat` mechanism to detect it.
        if (
          now - u.lastUpdatedTime >
          TypingIndicatorConfig.typingStartSendInterval +
            TypingIndicatorConfig.buffer
        ) {
          log.debug('RemoteTypingUser - cleared', {
            user: u.user,
          });
          updated = true;
          continue;
        }
        newRemoteTypingUsers.push(u);
      }
      if (updated) {
        setRemoteTypingUsers(newRemoteTypingUsers);
      }
      if (!stopWorkersRef.current) {
        setTimeout(run, 1000);
      }
    }, 1000);
  };

  const stopRemoteTypingUserCheckWorker = () => {
    stopWorkersRef.current = true;
  };

  const terminateChannelByRid = useLiveCallback(
    async (rid: string, reason: string | null) => {
      const channel = chatServiceRef.current.getChannel(rid);
      log.info('terminateChannel', {
        rid,
        channel: channel?.id,
        reason: reason,
      });
      if (channel) {
        channel.off(eventHandler);
        if (channel.id) {
          try {
            const stopWatching = await channel.stopWatching();
            log.info('stopWatching', { stopWatching });
          } catch (e) {
            log.error('stopWatching err', e);
          }
        } else {
          log.info('stopWatching, id not found.', { channel });
        }
      }
      chatServiceRef.current.delete(rid);
    }
  );

  const terminateChannels = useLiveCallback(async () => {
    if (recipientsRef.current) {
      await Promise.all(
        recipientsRef.current.map(async (r) => {
          await terminateChannelByRid(r.id, 'cleanup');
        })
      );
    }
  });

  const buildRecipients = useCallback(
    async (
      me: Participant,
      host: ChatParticipant | null,
      audiences: ChatParticipant[],
      venueId: string
    ) => {
      const _recipients: Recipient[] = [];

      const _recipientBuildHelper = async (recipient: Recipient) => {
        _recipients.push(recipient);
      };

      // Public
      await _recipientBuildHelper({
        id: venueId,
        username: 'Everyone',
        type: ChannelType.Public,
        icon: (
          <ChatGlobeIcon className='w-4 h-4 fill-current inline-block pr-1 pb-0.5' />
        ),
      });

      // Group
      if (ClientTypeUtils.isAudience(me)) {
        const groupRid = me.teamId ? me.teamId : FakeChannelId.Group;
        const oldGroupRid = recipientsRef.current.find(
          (o) => o.type === ChannelType.Group
        )?.id;
        if (oldGroupRid && oldGroupRid !== groupRid) {
          await terminateChannelByRid(oldGroupRid, 'rebuild');
        }
        if (me.teamId) {
          await _recipientBuildHelper({
            id: groupRid,
            username: 'My Team',
            type: ChannelType.Group,
            icon: (
              <ChatTeamIcon className='w-4 h-4 fill-current inline-block pr-1 pb-0.5' />
            ),
          });
        }
      }

      if (!privateChannelEnabled) return _recipients;

      const _buildPrivateRecipient = async (
        p: ChatParticipant,
        icon?: React.ReactNode | null
      ) => {
        if (p.clientId === me.clientId) return;
        const rid = getChatUserId(p);
        const name = getChatUserName(p);
        await _recipientBuildHelper({
          id: rid,
          username: name,
          type: ChannelType.Private,
          icon: icon,
          clientId: p.clientId,
        });
      };

      const hosts = host ? [host] : [];
      // Private
      await Promise.all(
        hosts.map(async (u) => {
          await _buildPrivateRecipient(
            u,
            <HostIcon className='w-4 h-4 fill-current inline-block pr-1 pb-0.5' />
          );
        })
      );
      await Promise.all(
        audiences.map(async (u) => {
          await _buildPrivateRecipient(u);
        })
      );

      return _recipients;
    },
    [privateChannelEnabled, terminateChannelByRid]
  );

  useEffect(() => {
    if (chatToClientId) {
      const recipient = findRecipientByClientId(chatToClientId);
      const chatTabActive = tabRef.current !== RightPanelTabState.People;
      if (!chatTabActive) {
        handlePanelUIAction({ input: 'click-chat' });
      }
      if (recipient) {
        setActiveRecipient(recipient);
      } else {
        handleSetError(ErrorMessageType.ChannelNotAvailableError, {
          channelType: ChannelType.Private,
        });
      }
      if (chatTabActive) {
        messageInputElRef.current?.focus();
      } else {
        setTimeout(() => {
          messageInputElRef.current?.focus();
        }, 20);
      }
      setChatToClientId(null);
    }
  }, [
    chatToClientId,
    findRecipientByClientId,
    handleSetError,
    handlePanelUIAction,
    setChatToClientId,
  ]);

  useEffect(() => {
    setFadeoutMessage(mode === ChatMode.Preview);
    // scroll to the bottom when switch the mode between full & preview mode,
    if (mode !== ChatMode.Preview) {
      chatServiceRef.current.cancelScrollToBottom();
      chatServiceRef.current.scheduleScrollToBottom(0);
    } else {
      chatServiceRef.current.scheduleScrollToBottom(0);
    }
  }, [mode, setFadeoutMessage]);

  // Register the user to Stream client
  useEffect(() => {
    (async () => {
      if (!localUid || !localUsername || !me?.id) return;
      try {
        const chatUser: SCUserType = {
          id: localUid,
          username: localUsername,
          color: myColor,
        };
        await terminateChannels();
        log.info('user connected');
        setChatUser(chatUser);
      } catch (e) {
        log.error('user connecting error: ', e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUid, localUsername, me?.id]);

  // Onetime initialization and cleanup registration
  // Initialization:
  //    1. Start async message worker
  // Cleanup:
  //    1. Stop async message worker
  //    2. Terminate all the channels
  useEffect(() => {
    chatServiceRef.current.startWorkers();
    startRemoteTypingUserCheckWorker();
    const _chatServiceRef = chatServiceRef;
    return () => {
      _chatServiceRef.current.stopWorkers();
      stopRemoteTypingUserCheckWorker();
      log.debug('cleanup', {
        recipientsCount: recipientsRef.current?.length,
      });
    };
  }, []);

  // Update channels & Rebuild recipients if the host/audiences/team changed
  // Channels only init once:
  //  * Public, Private Channel
  //
  // First time - Show loading indicator and error if it failed
  useEffect(() => {
    (async () => {
      if (!chatUser || !me) return;
      try {
        if (recipientsRef.current.length === 0) {
          setLoaded(false);
          setChatInited(false);
        }
        setRecipients(
          await buildRecipients(
            me,
            excludeHost ? null : host,
            audiences,
            venueId
          )
        );
        setLoaded(true);
        setChatInited(true);
      } catch (e) {
        log.error('failed to load the recipients', e);
        if (recipientsRef.current.length === 0) {
          setInitFailed(true);
        }
      }
    })();
  }, [
    chatUser,
    venueId,
    me,
    setLoaded,
    buildRecipients,
    host,
    audiences,
    setChatInited,
    excludeHost,
  ]);

  // reset the active recipient to the default one if,
  // 1. The current active recipient is outdated
  // 2. There is no active recipient selected
  useEffect(() => {
    let useDefault = false;
    if (activeRecipientRef.current) {
      const r = findRecipientByRid(activeRecipientRef.current.id);
      if (!r) {
        useDefault = true;
      } else {
        if (activeRecipientRef.current.username !== r.username) {
          useDefault = true;
        }
      }
    } else {
      useDefault = true;
    }
    if (useDefault && recipients.length > 0) {
      setActiveRecipient(recipientsRef.current[0]);
    }
  }, [findRecipientByRid, recipients.length]);

  useEffect(() => {
    if (
      mode === ChatMode.Full ||
      (mode === ChatMode.Preview && miniChatVersionRef.current === 'v1')
    ) {
      if (chatServiceRef.current.unreadCount > 0) {
        chatServiceRef.current.resetUnreadCount();
        chatServiceRef.current.scheduleScrollToBottom(0, false);
      }
    }
  }, [mode]);

  const handleRecipientChange = useCallback(
    (rid: string, autoFocus = true) => {
      const _activeRecipient = findRecipientByRid(rid);
      log.debug('active channel changed: ', {
        src: rid,
        channel: _activeRecipient?.username,
      });
      if (_activeRecipient) {
        setActiveRecipient(_activeRecipient);
        if (autoFocus) messageInputElRef.current?.focus();
      }
    },
    [findRecipientByRid]
  );

  const _getMentionedUserIds = (message: string) => {
    const mentionedUserIds = [];
    for (const mentionedRecipient of mentionedRecipientsRef.current) {
      const index = message.indexOf(
        `${MentionTrigger}${mentionedRecipient.username}`
      );
      if (index !== -1) {
        mentionedUserIds.push(mentionedRecipient.id);
      }
    }
    return mentionedUserIds;
  };

  const handleSendMessage = useCallback(async () => {
    if (!messageInputElRef.current || !chatUser) {
      return;
    }

    const message = messageInputElRef.current.value;
    if (message.trim() === '') {
      handleSetError(ErrorMessageType.EmptyMessageTextSizeError);
      return;
    }
    if (message.length > MaxMessageTextSize) {
      handleSetError(ErrorMessageType.MaxMessageTextSizeError);
      return;
    }
    messageInputElRef.current.value = '';
    setSendButtonDisabled(true);
    const mentionedUserIds = _getMentionedUserIds(message);
    const clientRefId = uuidv4();

    const payload = {
      text: message,
      type: 'message',
      mentioned_users: mentionedUserIds,
      extensions: {
        venueId: venueId,
        channelType: activeRecipientRef.current?.type ?? ChannelType.Public,
        clientRefId: clientRefId,
        senderClientId: myClientId,
        senderRid: localUidRef.current,
        teamId: me.teamId,
        receiverRid: activeRecipientRef.current?.id ?? '',
      },
    };

    sendMessage(JSON.stringify(payload));
    appendMessages([
      {
        ...payload,
        cid: 'Something',
        user: chatUser,
        id: clientRefId,
        local: true,
        recipient: undefined,
        created_at: new Date().toISOString(),
      },
    ]);
  }, [
    appendMessages,
    chatUser,
    handleSetError,
    setSendButtonDisabled,
    venueId,
    myClientId,
    me.teamId,
    sendMessage,
  ]);

  const handleReply = useCallback(
    (message: SCMessageType) => {
      switchActiveRecipient(message, true);
      messageInputElRef.current?.focus();
    },
    [switchActiveRecipient]
  );

  const handleDelete = useCallback(
    async (message: SCMessageType) => {
      try {
        if (message.local) {
          // TODO: real delete of local message
          // The local message can not be deleted on Stream Chat,
          // we need to wait the event (message.new) and get the real message id
          // so that we can issue the API call.
          handleSetError(ErrorMessageType.DeleteMessageLaterError);
          return;
        }
        replaceMessage({ ...message, type: StreamChatMessageType.Deleted });
      } catch (e) {
        log.error(`fail to delete message: ${message.id}`, e);
        replaceMessage(message);
        handleSetError(ErrorMessageType.DeleteMessageError);
      }
    },
    [handleSetError]
  );

  const handleAddMentionedRecipient = useCallback(
    (mentionedRecipient: Recipient) => {
      const exist = mentionedRecipientsRef.current.find(
        (r) => r.id === mentionedRecipient.id
      );
      if (!exist) {
        setMentionedRecipients([
          ...mentionedRecipientsRef.current,
          mentionedRecipient,
        ]);
      }
    },
    []
  );

  const inputEnabled =
    mode === ChatMode.Full ||
    (mode === ChatMode.Preview && miniChatConfig.inputEnabled);

  const sendTypingStart = useCallback(() => {
    if (!chatUser) return;

    const typingStartEvent = {
      type: 'typing_start',
      user: {
        id: chatUser.id,
        username: chatUser.username,
        color: chatUser.color,
      },
      extensions: {
        venueId: venueId,
        channelType: activeRecipientRef.current?.type ?? ChannelType.Public,
        senderClientId: myClientId,
        senderRid: localUidRef.current,
        teamId: me.teamId,
        receiverRid: activeRecipientRef.current?.id ?? '',
      },
    };

    sendMessage(JSON.stringify(typingStartEvent));
  }, [
    chatUser,
    sendMessage,
    venueId,
    activeRecipientRef,
    myClientId,
    localUidRef,
    me.teamId,
  ]);

  const sendTypingStop = useCallback(() => {
    if (!chatUser) return;

    const typingStopEvent = {
      type: 'typing_stop',
      user: {
        id: chatUser.id,
        username: chatUser.username,
        color: chatUser.color,
      },
      extensions: {
        venueId: venueId,
        channelType: activeRecipientRef.current?.type ?? ChannelType.Public,
        senderClientId: myClientId,
        senderRid: localUidRef.current,
        teamId: me.teamId,
        receiverRid: activeRecipientRef.current?.id ?? '',
      },
    };

    sendMessage(JSON.stringify(typingStopEvent));
  }, [
    chatUser,
    sendMessage,
    venueId,
    activeRecipientRef,
    myClientId,
    localUidRef,
    me.teamId,
  ]);

  return (
    <ChatContextProvider
      value={{
        chatService: chatServiceRef.current,
        mode: mode,
        setError: handleSetError,
      }}
    >
      <ChatNotifsContainer chatMode={mode} />
      <div
        className={`${
          mode !== ChatMode.None ? 'flex' : 'hidden'
        } flex-col gap-0.5 w-full rounded-xl text-white relative ${
          mode === ChatMode.Preview
            ? ''
            : mode === ChatMode.Full
            ? 'min-h-0'
            : ''
        }`}
      >
        {readyState !== ReadyState.OPEN && (
          <div className='flex flex-col items-center justify-center absolute inset-0 z-0 rounded-xl'>
            <img className='w-37 h-2.5' src={joiningImage} alt='joining...' />
            {initFailed && <div>Failed to load chat</div>}
          </div>
        )}
        {mode === ChatMode.Preview && miniChatConfig.version === 'v2' ? (
          <MessagePreview
            chatService={chatServiceRef.current}
            messages={messages}
            handleReply={handleReply}
            handleDelete={handleDelete}
            handlePanelUIAction={handlePanelUIAction}
            privateChannelEnabled={privateChannelEnabled}
            findRecipientByRid={findRecipientByRid}
          />
        ) : (
          <MessageList
            messageListElRef={messageListElRef}
            messages={messages}
            hasNewMessagesAfterStopScrolling={hasNewMessagesAfterStopScrolling}
            handleReply={handleReply}
            handleDelete={handleDelete}
            handlePanelUIAction={
              venueMode === VenueMode.Game ||
              ClientTypeUtils.isHost(myClientType)
                ? handlePanelUIAction
                : undefined
            }
            fadeoutMessage={fadeoutMessage}
            handleToggleFadeoutMessage={setFadeoutMessage}
            privateChannelEnabled={privateChannelEnabled}
            findRecipientByRid={findRecipientByRid}
          />
        )}
        {!error && mode === ChatMode.Full && (
          <TypingIndicator remoteTypingUsers={remoteTypingUsers} />
        )}
        {error && (
          <div
            className={`text-red-002 px-2 py-1 bg-black text-3xs w-full ${
              mode === ChatMode.Preview
                ? 'absolute bottom-22.5 rounded-xl'
                : 'rounded-none'
            }`}
          >
            {error}
          </div>
        )}
        {loaded && inputEnabled && readyState === ReadyState.OPEN && (
          <MessageInput
            messageInputElRef={messageInputElRef}
            activeRecipient={activeRecipient}
            recipients={recipients}
            sendButtonDisabled={sendButtonDisabled}
            handleRecipientChange={handleRecipientChange}
            handleSendMessage={handleSendMessage}
            handleToggleSendButton={setSendButtonDisabled}
            handleAddMentionedRecipient={handleAddMentionedRecipient}
            handleToggleFadeoutMessage={setFadeoutMessage}
            messageToStyle={privateChannelEnabled ? 'dropdown' : 'tab'}
            onTypingStart={sendTypingStart}
            onTypingStop={sendTypingStop}
          />
        )}
      </div>
    </ChatContextProvider>
  );
};

const ChatWrapper = (
  props: RightPanelTabProps & ChatProps
): JSX.Element | null => {
  const me = useMyInstance();
  if (!me) return null;
  return (
    <Chat
      {...props}
      me={me}
      privateChannelEnabled={getFeatureQueryParam('chat-private-channel')}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default React.memo(ChatWrapper);
export { ChatMode };
