import { type ReactNode, useEffect, useMemo } from 'react';

import MacChromeCam1x from '../../assets/img/mac-chrome-cam@1x.png';
import MacChromeCam3x from '../../assets/img/mac-chrome-cam@3x.png';
import MacChromeMic1x from '../../assets/img/mac-chrome-mic@1x.png';
import MacChromeMic3x from '../../assets/img/mac-chrome-mic@3x.png';
import MacFirefoxCam1x from '../../assets/img/mac-firefox-cam@1x.png';
import MacFirefoxCam3x from '../../assets/img/mac-firefox-cam@3x.png';
import MacFirefoxMic1x from '../../assets/img/mac-firefox-mic@1x.png';
import MacFirefoxMic3x from '../../assets/img/mac-firefox-mic@3x.png';
import { useInstance } from '../../hooks/useInstance';
import { buildSrcSet } from '../../utils/media';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { getAgentInfo } from '../../utils/user-agent';
import { Modal } from '../common/Modal';
import { useIsFirebaseConnected } from '../Firebase';
import { DeviceBlockedArrowIcon } from '../icons/Arrows';
import { CameraOffIcon } from '../icons/CameraOffIcon';
import { DeviceBlockedIcon } from '../icons/DeviceBlockedIcon';
import { MicrophoneOffIcon } from '../icons/MicrophoneOffIcon';
import { useMyInstance, useUpdateParticipant } from '../Player';
import { useUserContext } from '../UserContext';
import { useBlockedPrompt, useDeviceState } from './Context';

function useUpdateUserDeviceState() {
  const firebaseConnected = useIsFirebaseConnected();
  const me = useMyInstance();
  const { audioInputOptions, videoInputOptions, deviceOptionsUpdates } =
    useDeviceState();
  const { updateUserStates } = useUserContext();

  const numOfAudioInputs = useMemo(
    () => audioInputOptions.filter((o) => o.value !== '').length,
    [audioInputOptions]
  );

  const numOfVideoInputs = useMemo(
    () => videoInputOptions.filter((o) => o.value !== '').length,
    [videoInputOptions]
  );

  const updateParticipant = useUpdateParticipant();
  useEffect(() => {
    if (!firebaseConnected || !me?.clientId || deviceOptionsUpdates === 0)
      return;
    updateParticipant(me.clientId, {
      hasMicrophone: numOfAudioInputs !== 0,
      hasCamera: numOfVideoInputs !== 0,
    });
    const states = uncheckedIndexAccess_UNSAFE({});
    if (numOfAudioInputs === 0) {
      states['audio'] = false;
    }
    if (numOfVideoInputs === 0) {
      states['video'] = false;
    }
    if (Object.keys(states).length > 0) {
      updateUserStates(states);
    }
  }, [
    deviceOptionsUpdates,
    firebaseConnected,
    me?.clientId,
    numOfAudioInputs,
    numOfVideoInputs,
    updateParticipant,
    updateUserStates,
  ]);
}

type PromptProps = {
  handleDismiss: () => void;
};

function ModalWrapper(
  props: PromptProps & {
    header: string;
    children: ReactNode;
    width?: string;
  }
): JSX.Element {
  return (
    <Modal borderStyle='gray' className='bg-modal'>
      <div
        className={`${
          props.width || ''
        } flex flex-col items-center justify-center px-20 pt-10 pb-8`}
      >
        <div className='text-white font-medium text-2xl text-center'>
          {props.header}
        </div>
        {props.children}
        <button
          type='button'
          className='btn text-icon-gray text-sms font-medium px-10 py-2'
          onClick={props.handleDismiss}
        >
          Dismiss
        </button>
      </div>
    </Modal>
  );
}

function UserBlockedPrompt(
  props: PromptProps & { audioError?: Error | null; videoError?: Error | null }
): JSX.Element {
  const agentInfo = useInstance(() => getAgentInfo());

  let icon = <DeviceBlockedIcon />;
  if (agentInfo.browser.isFirefox) {
    if (props.videoError) {
      icon = (
        <div className='w-27 h-27 bg-secondary rounded-full relative flex items-center justify-center text-icon-gray'>
          <CameraOffIcon className='w-2/5 h-2/5 fill-current' />
        </div>
      );
    } else {
      icon = (
        <div className='w-27 h-27 bg-secondary rounded-full relative flex items-center justify-center text-icon-gray'>
          <MicrophoneOffIcon className='w-2/5 h-2/5 fill-current' />
        </div>
      );
    }
  }
  return (
    <div className='w-full h-full'>
      <ModalWrapper
        width='w-176'
        header='Camera / Microphone Blocked'
        {...props}
      >
        <div className='text-white text-sms my-10 flex flex-col items-start'>
          <p className='mb-5'>
            First, make sure you camera is not being used in any other programs
            (Zoom, Teams, Meet, etc).
          </p>
          <p className='mb-5'>
            Then, please allow Luna Park access to your camera and microphone
            with these steps:
          </p>
          {agentInfo.browser.isFirefox ? (
            <p>
              1. Click the cam/mic blocked icons located to the left of your
              browser's address bar
            </p>
          ) : (
            <p>
              1. Click the lock icon located to the left of your browser's
              address bar
            </p>
          )}
          <p>
            2. Allow access to your camera and microphone, and then refresh the
            page
          </p>
          <p className='mt-5'>
            You can always mute your camera and/or microphone at any time for
            privacy.
          </p>
        </div>
      </ModalWrapper>
      <div className='absolute z-50 left-1/2 transform -translate-x-1/2 flex flex-col items-center'>
        <div className='my-6'>
          <DeviceBlockedArrowIcon />
        </div>
        <div
          className='bg-light-gray w-160 h-17 
          text-white text-xl font-medium rounded-xl 
          flex items-center pl-24 relative'
        >
          <p>lunapark.com</p>
          <div className='absolute -left-10'>{icon}</div>
        </div>
      </div>
    </div>
  );
}

function SystemBlockedPrompt(
  props: PromptProps & { audioError?: Error | null; videoError?: Error | null }
): JSX.Element {
  const agentInfo = useInstance(() => getAgentInfo());

  const MacDescription = (
    <p>
      Your browser may not have access to your camera and/or microphone. To fix
      this problem, make sure your browser is allowed for both cam/mic under
      Security & Privacy in{' '}
      <a
        className='text-primary font-bold'
        target='_blank'
        href={`x-apple.systempreferences:com.apple.preference.security?${
          props.videoError ? 'Privacy_Camera' : 'Privacy_Microphone'
        }`}
        rel='noreferrer'
      >
        System Preferences
      </a>
      .
    </p>
  );
  const WinDescrption = (
    <p>
      Your browser may not have access to your camera and/or microphone. To fix
      this problem, please follow Microsoft's troubleshooting steps below.
    </p>
  );

  const UnknownDescrption = (
    <p>
      Your browser may not have access to your camera and/or microphone. To fix
      this problem, please check your system settings.
    </p>
  );

  const camSrcSet = useMemo(
    () =>
      agentInfo.browser.isChromium
        ? buildSrcSet([MacChromeCam1x, MacChromeCam3x], true)
        : agentInfo.browser.isFirefox
        ? buildSrcSet([MacFirefoxCam1x, MacFirefoxCam3x], true)
        : null,
    [agentInfo]
  );
  const micSrcSet = useMemo(
    () =>
      agentInfo.browser.isChromium
        ? buildSrcSet([MacChromeMic1x, MacChromeMic3x], true)
        : agentInfo.browser.isFirefox
        ? buildSrcSet([MacFirefoxMic1x, MacFirefoxMic3x], true)
        : null,
    [agentInfo]
  );

  buildSrcSet([MacChromeCam1x, MacChromeCam3x]);
  const MacInstruction = (
    <div className='text-center'>
      <div className='flex'>
        {camSrcSet && (
          <img
            srcSet={camSrcSet}
            alt='Camera Instruction'
            className='w-96 h-42'
          />
        )}
        <div className='w-7'></div>
        {micSrcSet && (
          <img
            srcSet={micSrcSet}
            alt='Microphone Instruction'
            className='w-96 h-42'
          />
        )}
      </div>
      <div className='text-icon-gray text-sms my-8'>
        You may be required to restart your browser after updating the settings.
      </div>
    </div>
  );

  const WinInstruction = (
    <div className='flex ml-5 mb-6 text-center'>
      <a
        href='https://support.microsoft.com/en-us/windows/manage-app-permissions-for-your-camera-in-windows-87ebc757-1f87-7bbf-84b5-0686afb6ca6b'
        className='text-primary font-bold text-sm mx-4'
        target='_blank'
        rel='noreferrer'
      >
        Troubleshoot Camera
      </a>
      <a
        href='https://support.microsoft.com/en-us/windows/turn-on-app-permissions-for-your-microphone-in-windows-10-94991183-f69d-b4cf-4679-c98ca45f577a'
        className='text-primary font-bold text-sm mx-4'
        target='_blank'
        rel='noreferrer'
      >
        Troubleshoot Microphone
      </a>
    </div>
  );

  return (
    <ModalWrapper
      width='w-auto'
      header='Camera or Microphone Access Blocked'
      {...props}
    >
      <div className='text-white text-sms my-8 w-160 text-center'>
        {agentInfo.os.isMac && MacDescription}
        {agentInfo.os.isWin && WinDescrption}
        {!(agentInfo.os.isMac || agentInfo.os.isWin) && UnknownDescrption}
      </div>
      {agentInfo.os.isMac && MacInstruction}
      {agentInfo.os.isWin && WinInstruction}
    </ModalWrapper>
  );
}

function Prompt(): JSX.Element | null {
  const { authorized, authorizedAudioError, authorizedVideoError } =
    useDeviceState();
  const hasError = !!(authorizedVideoError || authorizedAudioError);
  const [userBlockedPromptOpen, systemBlockedPromptOpen, setBlockedPromptOpen] =
    useBlockedPrompt();

  useEffect(() => {
    if (!authorized) return;
    if (hasError) {
      setBlockedPromptOpen(true);
    } else {
      setBlockedPromptOpen(false);
    }
  }, [authorized, hasError, setBlockedPromptOpen]);

  if (userBlockedPromptOpen) {
    return (
      <UserBlockedPrompt
        audioError={authorizedAudioError}
        videoError={authorizedVideoError}
        handleDismiss={() => setBlockedPromptOpen(false)}
      />
    );
  }
  if (systemBlockedPromptOpen) {
    return (
      <SystemBlockedPrompt
        audioError={authorizedAudioError}
        videoError={authorizedVideoError}
        handleDismiss={() => setBlockedPromptOpen(false)}
      />
    );
  }
  return null;
}

export function DeviceStateWatchdog(): JSX.Element | null {
  useUpdateUserDeviceState();

  return <Prompt />;
}
